import Checkbox from "material-ui/Checkbox";
import Icon from "material-ui/Icon";
import IconButton from "material-ui/IconButton";
import Input from "material-ui/Input/Input";
import { TableRow } from "material-ui/Table";
import TextField from "material-ui/TextField";
import R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CustomSelect from "../../../components/CustomSelect";
import {
  setBasketStatusId,
  setBasketQty,
  setBasketPromokod,
  setBasketAdminComment,
  setBasketGoodsManagerComment,
  removeBasket,
  setBasketSold,
} from "../../../modules/orders";
import { default as TableCell } from "../components/OrdersTableCell";

const { getBasketStatuses } = require("../../../selectors").default;

const memoizedBasketStatusOptions = R.memoize((basketStatuses) =>
  basketStatuses.map(({ id, name }) => ({ id, text: name }))
);

const imageStyle = {
  width: 70,
};

class BasketsListRow extends React.Component {
  onQtyChange = (e) => {
    this.props.setBasketQty(this.props.index, e.target.value);
  };

  onPromokodChange = (e) => {
    this.props.setBasketPromokod(this.props.index, e.target.value);
  };

  handleRemove = (e) => {
    this.props.removeBasket(this.props.index);
  };

  handleAdminCommentChange = (e) => {
    this.props.setBasketAdminComment(this.props.index, e.target.value);
  };

  handleGoodsManagerComment = (e) => {
    this.props.setBasketGoodsManagerComment(this.props.index, e.target.value);
  };

  handleStatusChange = (value) => {
    this.props.setBasketStatusId(this.props.index, value);
  };

  handleSoldChange = (e) => {
    this.props.setBasketSold(this.props.index, e.target.checked);
  };

  render() {
    const { basket } = this.props;
    const ownerColor =
      basket.OWNER_TYPE === "собственный товар" ? "#000" : "#e70808";
    return (
      <TableRow>
        <TableCell>{this.props.index + 1}</TableCell>
        <TableCell>
          <img alt="" style={imageStyle} src={basket.image_url} />
          <br />
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={basket.product_site_url}
          >
            На сайте
          </a>
        </TableCell>
        <TableCell>
          {basket.article}
          <br />
          {basket.brand}
          <br />
          {basket.collection_name}
          <br />
          {basket.SIZE}
          <br />
          <span style={{ color: ownerColor }}>{basket.OWNER_TYPE || ""}</span>
        </TableCell>
        <TableCell style={{ width: 60 }}>
          <Input value={basket.QUANTITY} onChange={this.onQtyChange} />
        </TableCell>
        <TableCell>{basket.tradex_remains}</TableCell>
        <TableCell>
          {basket.PRICE}
          <br />
          {basket.DISCOUNT_VALUE}
          <br />
          {basket.DISCOUNT_PRICE}
          <br />
        </TableCell>
        <TableCell>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Checkbox
              checked={basket.is_sold}
              onChange={this.handleSoldChange}
            />
          </div>
        </TableCell>
        <TableCell>
          <div
            style={{ display: "flex", flexDirection: "column", lineHeight: 2 }}
          >
            <Input value={basket.PROMOKOD} onChange={this.onPromokodChange} />
            <br />
            {
              // basket.PROMOKOD
            }
            <br />
            {basket.PROMOKOD_CONDITION}
          </div>
        </TableCell>
        <TableCell>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomSelect
              label="Статус"
              options={memoizedBasketStatusOptions(this.props.basketStatuses)}
              value={basket.STATUS_ID}
              onChange={this.handleStatusChange}
            />

            <TextField
              multiline
              label="Комментарий"
              value={basket.ADMIN_COMMENT}
              onChange={this.handleAdminCommentChange}
            />

            <TextField
              multiline
              label="Комментарий для товароведа"
              value={basket.GOODS_MANAGER_COMMENT}
              onChange={this.handleGoodsManagerComment}
            />
          </div>
        </TableCell>
        <TableCell>
          {(basket.stock_names || []).map((name, index) => (
            <span key={index}>{name}</span>
          ))}
        </TableCell>
        <TableCell>
          <IconButton color="accent" onClick={this.handleRemove}>
            <Icon className="material_icons">delete</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
}

export default connect(
  (state) => ({
    basketStatuses: getBasketStatuses(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setBasketStatusId,
        setBasketQty,
        setBasketPromokod,
        setBasketAdminComment,
        setBasketGoodsManagerComment,
        removeBasket,
        setBasketSold,
      },
      dispatch
    )
)(BasketsListRow);
